/** @jsx jsx */
import {jsx} from 'theme-ui'
import React, { useState } from 'react';
// import "react-dates/initialize"
// import { SingleDatePicker } from "react-dates"
// import "react-dates/lib/css/_datepicker.css"
import DateTimePicker from 'react-datepicker';
import { styles } from './react-datepicker.css';
import {
  FormControl as formControlStyles,
  FormGroup as formGroupStyles,
} from '@fify.at/gatsby-theme-base/src/components/Form/Form.styles';

const DatePickerWithFormik = ({
  startDateId,
  form: { setFieldValue, values },
  field,
  ...props
}) => {
  console.log(values);
  // const [date, setStartDpageate] = useState(null);
  // const [endDate, setEndDate] = useState(null);
  const [focusedInput, setFocusedInput] = useState(null);
  // const handleDatesChange = ({ date, endDate }) => {
  //   // setdate(date);
  //   // setEndDate(endDate);
  //   setFieldValue("date", date);
  //   setFieldValue("endDate", endDate);
  // };

  const [startDate, setStartDate] = useState(new Date());
  return (
    <DateTimePicker
      className="form-control  form-group"
      sx={{ '&&&':{'.react-datepicker-wrapper':{width: '100% !important'}}}}
      style={styles}
      selected={values.startDate}
      onChange={startDate => {
        setStartDate(startDate);
        setFieldValue('startDate', startDate);
      }}
      showTimeSelect
      timeFormat="HH:mm"
      timeIntervals={15}
      timeCaption="time"
      dateFormat="MMMM d, yyyy h:mm aa"
    />
  );
};

export default DatePickerWithFormik;
